define('Axelites_Core/js/design',[
    'jquery',
    'uiComponent',
    'domReady!'
], function ($,Component) {
    'use strict';

    return Component.extend({
        initialize: function (config) {
            this._super();
            var self = this;
            this.habillageGonfig = config.habillageGonfig;
            self.setConfig();
        },

        setConfig: function () {
            var self = this;
            var habillageGonfig = self.habillageGonfig;
            if(habillageGonfig.bodyClass != '' && !$("body").hasClass(habillageGonfig.bodyClass)){
                $("body").addClass(habillageGonfig.bodyClass);
            }
            /******* vertical Design ********/
            if ($('body.vertival-design').length > 0) {
                $( "<style>body.vertival-design #maincontent:before { background-image: url(" + habillageGonfig.verticalDesign + "); }</style>" ).appendTo( "head" );
                $( "<style>body.vertival-design #maincontent:after { background-image: url(" + habillageGonfig.verticalDesign + "); }</style>" ).appendTo( "head" );
            }
            /******* Price Tag ********/
            if(habillageGonfig.PriceTag != ''){
                $( "<style>.promo-design-tag { background-image: url(" + habillageGonfig.PriceTag+ "); }</style>" ).appendTo( "head" );
            }
        },
    });
});

