define('Axelites_Wishlist/js/wishlist',[
    'jquery',
    'ko',
    'uiComponent',
    'Magento_Customer/js/customer-data',
    'mage/translate',
    "Axelites_Core/js/model/ax-core",
    'domReady!'
], function ($, ko, Component, customerData, $t,axCore) {
    'use strict';

    return Component.extend({
        initialize: function (config) {
            if($('.wishlist-header').length == 0 && $('.wishlist-mobile-header').length == 0){
                return;
            }
            // return;//Trying to identify the root cause problem of 502 error

            this.wishlist = customerData.get('ax-wishlist');
            this.isListPage = $('.axwishlist-index-index').length;
            this.isCatalogCategoryPage = $('.catalog-category-view').length;
            this.isCatalogViewPage = $('.catalog-product-view').length;
            this.isSearchResultPage = $('.catalogsearch-result-index').length;
            this.config = config;
            this._super();
            var self = this;
            // customerData.reload(['ax-wishlist'], false);
            self.addItem();
            self.removeItem();

            if(Object.keys(this.wishlist()).length != 0){
                self.initializeWishListData(this.wishlist());
            }

            self.wishlist.subscribe(function (data) {
                self.initializeWishListData(data);
            });
        },

        initializeWishListData: function (data){
            var href =  $('.wishlist-header').find(".sprite-svg-icon use").attr("xlink:href");
            var href_mobile = $('.wishlist-header-mobile').find(".sprite-svg-icon use").attr("xlink:href");
            if(data.cnt_items != 0){
                var cnt = data.cnt_items;
                if($('.mobile-design').length>0){
                    cnt = '('+data.cnt_items+')';
                }

                $('.ax-wishlist-cnt').text(cnt);
                $('.wishlist-header').addClass('active');
                $('.wishlist-header-mobile').addClass('active');
                $('.wishlist-header .svg-sprite-hover-red').addClass('active');
                if(href != undefined && !href.includes('_red')){
                    $('.wishlist-header').find(".sprite-svg-icon use").attr("xlink:href", href+"_red");
                }
                if(href_mobile != undefined && !href_mobile.includes('_red2')){
                    $('.wishlist-header-mobile').find(".sprite-svg-icon use").attr("xlink:href", href_mobile+"_red");
                }
            }else {
                $('.wishlist-header').removeClass('active');
                $('.wishlist-header-mobile').removeClass('active');
                $('.wishlist-header .svg-sprite-hover-red').removeClass('active');
                $('.ax-wishlist-cnt').text('');
                if(href != undefined && href.includes('_red')){
                    var link = href.replace("_red", "");
                    $('.wishlist-header').find(".sprite-svg-icon use").attr("xlink:href", link);
                }
                if(href_mobile != undefined && href_mobile.includes('_red')){
                    var link_mobile = href_mobile.replace("_red", "");
                    $('.wishlist-header-mobile').find(".sprite-svg-icon use").attr("xlink:href", link_mobile);
                }
            }
            if (data.axwishlist.length != 0 && (this.isListPage || this.isCatalogCategoryPage || this.isSearchResultPage)) {
                axCore.waitForElement(".ais-InfiniteHits-item", this.checkWishlistData.bind(null,data));
            }
            if( this.isCatalogViewPage && data.axwishlist.length != 0){
                axCore.waitForElement(".wishlist-product-top", this.checkWishlistData.bind(null,data));
            }
        },

        checkWishlistData: function(data){
            $(".typesense-catalog-product-color-switch").attr("data-wishlist", 0);
            $.each(data.axwishlist, function (k, v) {
                $('.typesense-catalog-product-color-switch-' + v).attr("data-wishlist", 1);
                var elem = $('.toguestwishlist');
                var currentElement = elem.filter(function (index) {
                    return $(this).attr('data-product') == v;
                });
                var currentHref = currentElement.find(".sprite-svg-icon.favoris use").attr("xlink:href");
                currentElement.addClass('remove-from-guest-wishlist')
                    .removeClass('add-to-guest-wishlist')
                    .closest('.remove-from-guest-wishlist').find('.favoris-text').text($t('Remove from wishlist'));

                if(currentHref != undefined && !currentHref.includes('_red2')){
                    currentElement.find(".sprite-svg-icon.favoris use").attr("xlink:href", currentHref + "_red2");
                }
            });
        },

        addItem: function () {
            var self = this;
            $(document).on('click', '.add-to-guest-wishlist', function () {
                var elem = $(this);
                elem.addClass('remove-from-guest-wishlist');
                elem.removeClass('add-to-guest-wishlist');
                var href =  elem.find(".sprite-svg-icon use").attr("xlink:href");
                $.ajax({
                    url: self.config.manageUrl,
                    type: 'POST',
                    data: {
                        id: elem.attr('data-product'),
                        action: 'add'
                    },
                    success: function (response) {
                        elem.addClass('remove-from-guest-wishlist');
                        elem.removeClass('add-to-guest-wishlist');
                        elem.find('.favoris-text').text($t('Remove from wishlist'));
                        if(href && !href.includes('_red2')){
                            elem.find(".sprite-svg-icon use").attr("xlink:href", href+"_red2");
                        }
                    },
                    error: function (jqXHR, exception) {
                        elem.addClass('add-to-guest-wishlist');
                        elem.removeClass('remove-from-guest-wishlist');
                        if(href && href.includes('_red2')){
                            var link = href.replace("_red2", "");
                            elem.find(".sprite-svg-icon use").attr("xlink:href", link);
                        }
                    }
                });
            });
        },

        removeItem: function () {
            var self = this;
            $(document).on('click', '.remove-from-guest-wishlist', function () {
                var elem = $(this);
                elem.addClass('add-to-guest-wishlist');
                elem.removeClass('remove-from-guest-wishlist');
                var href =  elem.find(".sprite-svg-icon use").attr("xlink:href");
                $.ajax({
                    url: self.config.manageUrl,
                    type: 'POST',
                    data: {
                        id: elem.attr('data-product'),
                        action: 'remove'
                    },
                    success: function (response) {
                        elem.addClass('add-to-guest-wishlist');
                        elem.removeClass('remove-from-guest-wishlist');
                        elem.find('.favoris-text').text($t('Add to wishlist'));
                        if (self.isListPage) {
                            elem.closest('.product-item').remove();
                        }
                        if(href && href.includes('_red2')){
                            var link = href.replace("_red2", "");
                            elem.find(".sprite-svg-icon use").attr("xlink:href", link);
                        }
                    },
                    error: function (jqXHR, exception) {
                        elem.addClass('add-to-guest-wishlist');
                        elem.removeClass('remove-from-guest-wishlist');
                        if(href && href.includes('_red2')){
                            var link = href.replace("_red2", "");
                            elem.find(".sprite-svg-icon use").attr("xlink:href", link);
                        }
                    }
                });
            });
        },

        // switchItem: function () {
        //     var self = this;
        //     $(document).on('click', ".typesense-catalog-product-color-switch", function () {
        //         var elem = $(this).closest('.typesense-product-wrap').find('.wishlist-listing .toguestwishlist').first();
        //         elem.attr('data-product', $(this).attr('data-id'));
        //         var href =  elem.find(".sprite-svg-icon use").attr("xlink:href");
        //         if (parseInt($(this).attr('data-wishlist'))) {
        //             elem.addClass('remove-from-guest-wishlist');
        //             elem.removeClass('add-to-guest-wishlist');
        //             if(href && !href.includes('_red2')){
        //                 elem.find(".sprite-svg-icon use").attr("xlink:href", href+"_red2");
        //             }
        //         } else {
        //             elem.addClass('add-to-guest-wishlist');
        //             elem.removeClass('remove-from-guest-wishlist');
        //             if(href && href.includes('_red2')){
        //                 var link = href.replace("_red2", "");
        //                 elem.find(".sprite-svg-icon use").attr("xlink:href", link);
        //             }
        //         }
        //     });
        // }
    });
})
;
