/**
 * @todo centralize all functions helpers here
 */
define('Axelites_Core/js/model/ax-core',[
    'jquery',
    'ko',
    'Mageplaza_Osc/js/model/osc-data',
    'underscore',
    'mage/storage',
    'Magento_Checkout/js/model/error-processor',
], function ($, ko, oscData, _,storage,errorProcessor) {
    'use strict';

    return {
        isGuestCheckout: ko.observable(false),
        availableShippingRatesForParis: ko.observableArray([]),
        /**
         * Merge two objects
         * @param to
         * @param from
         * @returns {*}
         */
        mergeObject: function (to, from) {
            for (var n in from) {

                if (typeof to[n] != 'object') {
                    to[n] = from[n];
                } else if (typeof from[n] == 'object') {
                    to[n] = this.mergeObject(to[n], from[n]);
                }
            }
            return to;
        },
        /**
         * Wait for element to exist in dom
         * @param selector
         * @param callback
         */
        waitForElement: function (selector, callback) {
            var MuObserver = new MutationObserver(() => {
                if (document.querySelectorAll(selector).length) {
                    callback();
                    MuObserver.disconnect();
                }
            });
            MuObserver.observe(document.documentElement, {
                attributes: true,
                childList: true,
                subtree: true,
                characterData: true
            });
        },

        /**
         * getAvailableShippingRatesForParis
         */
        getAvailableShippingRatesForParis: function (){
            var self = this;
            var address;
            require(['Magento_Checkout/js/model/resource-url-manager', 'Magento_Checkout/js/model/quote'], function (resourceUrlManager, quote){
                var serviceUrl = resourceUrlManager.getUrlForEstimationShippingMethodsForNewAddress(quote);
                var payload = JSON.stringify({
                        address: {
                            'region': 'Paris',
                            'country_id': 'FR',
                            'postcode': '75000',
                        }
                    }
                );
                storage.post(
                    serviceUrl, payload, false
                ).done(
                    function (result) {
                        if(result){
                            self.availableShippingRatesForParis([]);
                            var shippingMethodsInParis = ['trusk','livraisonetageidf','up_stairs_idf_premium','up_stairs_idf_gold'];
                            _.each(result, function (rate) {
                                if(_.contains(shippingMethodsInParis, rate['method_code'])){
                                    self.availableShippingRatesForParis.push(rate);
                                }
                            });
                        }
                    }
                ).fail(
                    function (response) {
                        errorProcessor.process(response);
                    }
                );
            });
        },
        /**
         *
         * @returns {boolean}
         */
        isTruskAvailableForParis: function(quote){
            if(
                typeof window.checkoutConfig.axTruskConfig !== "undefined"
                && typeof window.checkoutConfig.axTruskConfig.specificDepartment !== "undefined"
                && typeof quote.shippingAddress() !== "undefined"
                && quote.shippingAddress() !== null
                && typeof quote.shippingAddress().postcode !== "undefined"
                && typeof quote.shippingAddress().postcode === "string"
            ){
                var postCode = quote.shippingAddress().postcode;
                if(typeof postCode !== "undefined" && null !== postCode){
                    return this.axInArray(postCode.toString().substr(0,2), window.checkoutConfig.axTruskConfig.specificDepartment);
                }
            }
            return false;
        },
        /**
         *
         * @param needle
         * @param haystack
         * @returns {boolean}
         */
        axInArray: function (needle, haystack){
            return $.inArray(needle, haystack) !== -1;
        },

        /**
         *
         * @returns {boolean}
         */
        isCanaryIlandsQuote: function(quote){
            if(
                typeof window.checkoutConfig.ax_spain !== "undefined"
                && typeof window.checkoutConfig.ax_spain.canary_ilands_postal_codes !== "undefined"
                && typeof quote.shippingAddress() !== "undefined"
                && quote.shippingAddress() !== null
                && typeof quote.shippingAddress().postcode !== "undefined"
                && typeof quote.shippingAddress().postcode === "string"
                && typeof quote.shippingAddress().countryId === "string"
            ){
                var postCode = quote.shippingAddress().postcode;
                var countryId = quote.shippingAddress().countryId;
                if(typeof postCode !== "undefined" && null !== postCode && typeof countryId !== "undefined" && null !== countryId && countryId == 'ES'){
                    var firstTwoLetters = postCode.toString().substring(0, 2);
                    return this.axInArray(firstTwoLetters, window.checkoutConfig.ax_spain.canary_ilands_postal_codes);
                }
            }
            return false;
        },

        isDomtomQuote: function(quote){
            if(
                typeof window.checkoutConfig.axMenzzoShippingConfig.axDomtomDep !== "undefined"
                && typeof quote.shippingAddress() !== "undefined"
                && quote.shippingAddress() !== null
                && typeof quote.shippingAddress().postcode !== "undefined"
                && typeof quote.shippingAddress().postcode === "string"
                && typeof quote.shippingAddress().countryId === "string"
            ){
                var postCode = quote.shippingAddress().postcode;
                var countryId = quote.shippingAddress().countryId;
                if(typeof postCode !== "undefined" && null !== postCode && typeof countryId !== "undefined" && null !== countryId && countryId == 'FR'){
                    let postCodeString = postCode.toString();
                    let dep = postCodeString.slice(0,3);
                    return this.axInArray(dep, window.checkoutConfig.axMenzzoShippingConfig.axDomtomDep);
                }
            }
            return false;
        },

        /**
         *
         * @param clickedElement
         * @constructor
         */
        CloseAllHeaderLinks: function(clickedElement){
            this.CloseHeaderMenu(clickedElement);
            this.CloseHeaderMiniCart(clickedElement);
            this.CloseHeaderSearch(clickedElement);
        },
        /**
         *
         * @param clickedElement
         * @constructor
         */
        CloseHeaderMenu: function(clickedElement){
            if((clickedElement != '.action.nav-toggle') && $('html').hasClass('nav-open')){
                $('.action.nav-toggle').trigger('click');
            }
        },
        /**
         *
         * @param clickedElement
         * @constructor
         */
        CloseHeaderMiniCart: function(clickedElement){
            if((clickedElement != '.minicart-wrapper') && $('.minicart-wrapper').hasClass('active')){
                $('.minicart-wrapper').trigger('click');
            }
        },
        /**
         *
         * @param clickedElement
         * @constructor
         */
        CloseHeaderSearch: function(clickedElement){
            if((clickedElement != '.search-link-mobile') && $('.search-link-mobile').hasClass('active')){
                $('.search-link-mobile').trigger('click');
            }
        }
    };
});

